import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ToggleButton,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useAppDispatch, useAppSelector } from "store";
import { InspectionDialog } from "./inspection/InspectionDialog";
import {
  selectSelectedJobVisit,
  setSelectedInspection,
  updateInspection,
} from "store/slices/jobs.store";
import StyledTextField from "components/StyledTextField";
import { Inspection } from "models/inspection";
import { StyledToggleButtonGroup } from "components/StyledToggleButtonGroup";
import { FilterAppBar } from "components/FilterAppBar";
import { BarcodeScannerDialog } from "components/barcodeScan";
import { scanditLicense } from "env";
import { InspectionFilterDialog } from "./inspection/InspectionFilterDialog";
import { filterInspectionsList } from "helpers/filterInspectionsList";
import { defaultInspectionsFilter, InspectionFilters } from "models/InspectionsFilter";
import { FormattedMessage, useIntl } from "react-intl";
import { TabProps } from "pages/jobs/jobDetails/visit/TabProps";
import { DeviceTestStatusEnum } from "./inspection/DeviceTestStatusEnum";

export const InspectionsTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const dispatch = useAppDispatch();
  const hasBarcodeScanner = !!scanditLicense();
  const [openInspectionDialog, setOpenInspectionDialog] = useState(false);
  const [openInspectionFilterDialog, setOpenInspectionFilterDialog] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [search, setSearch] = useState<string>("");
  const { inspections } = useAppSelector(selectSelectedJobVisit);
  const [filter, setFilter] = useState<InspectionFilters>(defaultInspectionsFilter);
  const intl = useIntl();
  const filteredInspectionsList = filterInspectionsList(inspections, filter, search);
  const iconStyle = { marginLeft: 1 };

  useEffect(() => {
    const fab = undefined;
    setFab(fab);
  }, [setFab]);

  return (
    <Container>
      <FilterAppBar
        onClick={setOpenInspectionFilterDialog}
        foundCount={filteredInspectionsList.length}
      />
      <Grid item container alignItems="start" justifyContent="space-between" wrap="nowrap">
        <Grid item width="100%">
          <StyledTextField
            fullWidth
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            variant="outlined"
            placeholder={intl.formatMessage({ id: "inspection.search" })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
            data-testid="InspectionTab-Filter"
          />
        </Grid>
        {hasBarcodeScanner && (
          <Grid item>
            <IconButton onClick={() => setOpenScanner(true)} size="large" sx={{ pr: 0, ml: 1 }}>
              <CropFreeIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <List>
        {filteredInspectionsList.map((inspection: Inspection) => {
          return (
            <div key={inspection.specification.id}>
              <ListItem
                key={inspection.specification.id}
                disablePadding
                onClick={() => {
                  dispatch(setSelectedInspection({ inspection }));
                  setOpenInspectionDialog(true);
                }}
                disableGutters
                secondaryAction={
                  <IconButton sx={{ padding: 0 }}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                data-testid={`InspectionTab-ListItem}`}
              >
                <ListItemButton disableGutters>
                  <Grid>
                    <Grid>
                      <Typography variant="body1" fontWeight={700}>
                        {`${inspection.specification.description} (${inspection.specification.partNumber})`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="body1">{inspection.specification.location}</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="body1">
                        {inspection.specification.subLocation}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="body2">
                        {`Last Service ${inspection.specification.resultOfLastService} on ${inspection.specification.lastServiceDate}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
              <StyledToggleButtonGroup
                value={inspection.testResult}
                onChange={(_, value) => {
                  dispatch(updateInspection({ inspection: { ...inspection, testResult: value } }));
                }}
                exclusive
                fullWidth
                size="small"
                aria-describedby="bool-type-error-text"
                data-testid="InspectionTestResultToggle"
              >
                <ToggleButton value={DeviceTestStatusEnum.Pass} color="success">
                  <FormattedMessage id="inspection.pass" />
                  <CheckIcon sx={iconStyle} />
                </ToggleButton>
                <ToggleButton value={DeviceTestStatusEnum.Fail} color="error">
                  <FormattedMessage id="inspection.fail" />
                  <CloseIcon sx={iconStyle} />
                </ToggleButton>
                <ToggleButton value={DeviceTestStatusEnum.NotTested}>
                  <FormattedMessage id="inspection.notTested" />
                </ToggleButton>
              </StyledToggleButtonGroup>
            </div>
          );
        })}
      </List>
      <InspectionDialog
        open={openInspectionDialog}
        handleClose={() => {
          setOpenInspectionDialog(false);
          dispatch(setSelectedInspection({ inspection: null }));
        }}
      />
      {hasBarcodeScanner && (
        <BarcodeScannerDialog
          open={openScanner}
          setOpen={(open: boolean) => setOpenScanner(open)}
          submitValue={(value: string) => {
            setSearch(value);
            setOpenScanner(false);
          }}
        />
      )}
      <InspectionFilterDialog
        open={openInspectionFilterDialog}
        handleClose={() => {
          setOpenInspectionFilterDialog(false);
        }}
        filter={filter}
        setFilter={setFilter}
      />
    </Container>
  );
};
