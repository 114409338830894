import { toDateString } from "helpers";
import { WritableDraft } from "immer/dist/internal";
import { Job } from "models/Job";
import { initialVisitFormValues } from "models/jobVisitForm";
import { User } from "models/user";
import { JobCategoryType, JobSymptomType } from "operations/schema/schema";
import { State } from "store/slices/jobs.store";
import { storeVisitsToLocal } from "store/slices/jobs.store";

export const initializeJob = (
  state: WritableDraft<State>,
  job: Job,
  hasCauses: boolean,
  jobCategories: JobCategoryType[],
  symptoms: JobSymptomType[],
  userVar: User
) => {
  const travelTimes = job.travelTimes.filter((tt) => tt.startTime || tt.stopTime);
  state.jobVisits[job.id] = {
    checklists: [
      ...job.checklists.map((cl) => ({
        checklist: cl,
        uploaded: false,
      })),
    ],
    files: [],
    usedParts: [], // TODO; Initialize
    inspections: job.equipment?.specifications
      ? [
          ...job.equipment.specifications.map((s) => ({
            specification: s,
            testResult: "",
            notes: "",
          })),
        ]
      : [],
    workTasks: [...job.tasks],
    travelTimes: (travelTimes || []).map((tt) => ({
      startDate: toDateString(tt.startTime!),
      startTime: toDateString(tt.startTime!),
      stopDate: toDateString(tt.stopTime) || undefined,
      stopTime: toDateString(tt.stopTime) || undefined,
    })),
    travelEta: toDateString(job.travelETA) || undefined,
    travelMileage: job.totalMileage || 0,
    followUp: (() => {
      let bool = !!job.preOrderedParts.length;
      return {
        engineerId: userVar ? parseInt(userVar?.externalId!) : 0,
        symptomDescription: job.symptomDescription || "",
        symptomCode1: symptoms.find((x) => job.symptoms[0] === x.description)?.code || "",
        symptomCode2: "",
        symptomCode3: "",
        followUpChecked: bool,
        followUpDisabled: bool,
        followUpPartsChecked: bool,
        followUpPartsDisabled: bool,
      };
    })(),
    signatureCustomer: undefined,
    signatureEngineer: undefined,
    newWorkUnitId: undefined,
    autoEndTime: true,
    ...initialVisitFormValues(job, jobCategories),
    errors: {},
    signoffErrors: {},
    submitLoading: false,
    hasCategories: !!jobCategories.length,
    hasCauses: hasCauses,
  };
  storeVisitsToLocal(state);
};
