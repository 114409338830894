import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Box, Dialog, Grid, IconButton, ListItemText, Toolbar, Typography } from "@mui/material";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { intlFormatDate } from "helpers";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "store";
import { updateInspection } from "store/slices/jobs.store";

type InspectionDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const InspectionDialog: FC<InspectionDialogProps> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { open, handleClose } = props;
  const { selectedInspection: inspection } = useAppSelector((s) => s.jobs);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
      data-testid="inspectionDialog"
    >
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="inspectionDialogCloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar.label" paddingRight="36px">
            <ListItemText
              primary={`${inspection?.specification.id} / ${inspection?.specification.description}`}
              sx={{ maxWidth: "80%" }}
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <StyledContainer>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row">
            <Grid item mr={1}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage id="inspection.description" />:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{inspection?.specification.description}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item mr={1}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage id="inspection.location" />:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{inspection?.specification.location}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item mr={1}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage id="inspection.quantity" />:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{inspection?.specification.quantity}</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item mr={1}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage id="inspection.dateInstalled" />:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {inspection?.specification.dateInstalled
                ? intlFormatDate(intl, inspection.specification.dateInstalled)
              : "null"}  
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
              <Typography variant="body2">
                  <Box
                    component="span"
                    sx={{
                      fontWeight: 'bold',
                      color: 'grey',
                    }}
                  >
                    Last Service:
                  </Box>{' '}
                <Box
                  component="span"
                  sx={{
                    color: inspection?.testResult === '1' 
                    ? 'green' 
                    : inspection?.testResult === '2'
                    ? 'red'
                    : 'blue',
                    fontWeight: 'bold',
                  }}
                >
                  {inspection?.testResult === '1'
                    ? "PASS"
                  : inspection?.testResult === '2'
                  ? "FAIL"
                  : "NOT TESTED"}
                </Box>
                {inspection?.testResult === '1' || inspection?.testResult === '2'
                ? ` on ${inspection?.specification.lastServiceDate
                    ? intlFormatDate(intl, inspection?.specification.lastServiceDate)
                    : "N/A"}`
                : ""}
              </Typography>
          </Grid>
          <Grid item>
            <StyledTextField
              value={inspection?.notes || ""}
              label={intl.formatMessage({ id: "note" })}
              onChange={async (e) => {
                if (!inspection) return;
                dispatch(
                  updateInspection({
                    inspection: { ...inspection, notes: e.target.value },
                  })
                );
              }}
              multiline
              rows={15}
              data-testid="InspectionDialog-Text"
            />
          </Grid>
        </Grid>
      </StyledContainer>
    </Dialog>
  );
};
