import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { FilterAppBar } from "components/FilterAppBar";
import { JobCalendarView } from "components/job/JobCalendarView";
import { JobList } from "components/job/JobList";
import { JobListSkeleton } from "components/job/JobListSkeleton";
import { useEffectOnce } from "helpers/useEffectOnce";
import { PlannerFilter, PlannerFilterStorageKey } from "models/PlannerFilter";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store";
import { setPage, setSelectedJob } from "store/root.store";
import { getSymptoms } from "store/slices/cache.store";
import { clearHistoryState, setHistorySelectedJob } from "store/slices/history.store";
import { setAppBar } from "store/slices/menu.store";
import {
  getEngineers,
  getPlannerJobs,
  selectLoadingJobs,
  selectPlannerFilterCount,
  selectJobs as selectPlannerJobs,
  setPlannerFilter,
  setPlannerSelectedJob,
} from "store/slices/planner.store";
import { setSelectedJob as setVisitSelectedJob } from "store/slices/jobs.store";
import { PlannerFilterDialog } from "./plannerDetails/PlannerFilterDialog";

const sortingType = "planner";

export const PlannerPage: FC = () => {
  const { plannerDisplay } = useAppSelector((s) => s.planner);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectPlannerJobs, shallowEqual);
  const loadingJobs = useAppSelector(selectLoadingJobs);
  const filterCount = useAppSelector(selectPlannerFilterCount);
  const { state }: any = useLocation();
  const openFilter: boolean = state?.openFilter ?? false;
  const [filterDialogOpen, setFilterDialogOpen] = useState(openFilter);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffectOnce(() => {
    const plannerFilterString = localStorage.getItem(PlannerFilterStorageKey);
    let storedPlannerFilter: PlannerFilter | null = null;
    if (!openFilter && plannerFilterString) {
      storedPlannerFilter = JSON.parse(plannerFilterString) as PlannerFilter;
      if (storedPlannerFilter.specificDate)
        storedPlannerFilter.specificDate = new Date(storedPlannerFilter.specificDate);
    }
    if (storedPlannerFilter != null) {
      dispatch(setPlannerFilter({ plannerFilter: storedPlannerFilter }));
    }
  });

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({ id: "menu.planner" }),
        sortingType,
        refetchEnabled: true,
        calendarView: plannerDisplay === "calendar",
      })
    );

    dispatch(setPage({ page: "planner" }));
    dispatch(setSelectedJob({ jobId: undefined }));
    dispatch(setVisitSelectedJob({ jobId: undefined }));
    dispatch(setPlannerSelectedJob({ jobId: undefined }));
    dispatch(setHistorySelectedJob({ jobId: undefined }));
    dispatch(clearHistoryState());
  }, [dispatch, intl, plannerDisplay]);

  const dispatchGetPlannerJobs = useCallback(() => {
    dispatch(getPlannerJobs());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEngineers());
    dispatch(getSymptoms());
  }, [dispatch]);

  useEffect(() => {
    if (initialLoad && !openFilter) {
      dispatchGetPlannerJobs();
      setInitialLoad(false);
    }
  }, [dispatchGetPlannerJobs, initialLoad, openFilter]);

  return (
    <Container>
      <FilterAppBar
        onClick={setFilterDialogOpen}
        filterCount={filterCount}
        foundCount={jobs.length}
        type="jobs"
      />
      {loadingJobs ? (
        <>
          <LinearProgress />
          <JobListSkeleton />
        </>
      ) : (
        <>
          {plannerDisplay === "calendar" && (
            <JobCalendarView
              jobs={jobs}
              refetchJobs={dispatchGetPlannerJobs}
              loading={loadingJobs}
              toLink="/planner"
              plannerMode
            />
          )}
          {plannerDisplay === "list" && (
            <JobList
              jobs={jobs}
              loading={loadingJobs}
              sortingType={sortingType}
              toLink="/planner"
              showBorder
              showEngineerName
            />
          )}
        </>
      )}
      <PlannerFilterDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        onClose={(dirty) => {
          if (dirty || initialLoad) {
            setInitialLoad(false);
            dispatchGetPlannerJobs();
          }
        }}
      />
    </Container>
  );
};
